import { mapHelper } from "@/utils/utils.js";
//评价状态
const isCommentStatus = [
  {
    value: 1,
    text: "非常差",
  },
  {
    value: 2,
    text: "差",
  },
  {
    value: 3,
    text: "一般",
  },
  {
    value: 4,
    text: "满意",
  },
  {
    value: 5,
    text: "非常满意",
  },
];

const { map: isCommentStatusMap, setOps: isCommentStatusOps } =
  mapHelper.setMap(isCommentStatus);

export { isCommentStatus, isCommentStatusMap };
